.no-border td {
  border-top: none;
}

.table-button-right {
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 8px;
  float: right;
}

.table-pagination-right {
  margin-top: 8px;
  float: right;
}

.table-content-center {
  text-align: center;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.input-range__slider {
  background: $primary;
  border: 1px solid $primary;
}

.input-range__track--active {
  background: $primary;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.modal-xxl {
  max-width: 1560px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

#trophy-gold {
  color: #ffd700;
}

#trophy-silver {
  color: #bec0c1;
}

#trophy-bronze {
  color: #bd993b;
}

#login-page {
  background: linear-gradient(to bottom right, $primary-light, $primary-dark);
}

#login-page .app-footer {
  background: #00000000;
  border-top: none;
  color: white;
}

#login-page .app-footer a {
  color: white;
}

#dashboard-time {
  background: linear-gradient(to bottom, $primary-light, $primary-dark);
}

.cursor {
  cursor: move;
}